var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":"选择用户","size":"lg","show":_vm.shown},on:{"update:show":function (val) { return _vm.$emit('update:shown', val); }},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.$emit('update:shown', false )}}},[_vm._v("关闭")])]},proxy:true}])},[_c('CDataTable',{ref:"table",attrs:{"hover":true,"striped":true,"border":true,"small":true,"fixed":true,"fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"aid",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.aid)+" ")])]}},{key:"nick",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('myavatar',{attrs:{"src":item.avat,"nick":item.nick,"imgw":"18px"}})],1)]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$dateFmt(item.created))+" ")])]}},{key:"btns",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"info","square":"","size":"sm","disabled":item.added==true},on:{"click":function($event){return _vm.addFun(item)}}},[_vm._v(" 添加 ")])],1)]}}])}),_c('CPagination',{staticStyle:{"float":"right"},attrs:{"activePage":_vm.page,"pages":_vm.pages},on:{"update:activePage":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }